import { addLoader, removeLoader } from './loader.js';
import loadImages from './loadImages';

const $pagingNext = $('.load-more');
let loadingArticles = false;

export default function() {
	$pagingNext.on('click', function(event) {
		event.preventDefault();

		const $this = $(this);

		if ( loadingArticles ) {
			return;
		}

		loadingArticles = true;
		addLoader();

		$.ajax({
			url: $this.attr('href'),
			type: 'GET',
			dataType: 'html',
			success: function(data) {
				addArticlesOutput( data );
			},
			error: function(errorThrown) {
				console.log(errorThrown);
			},
			complete: function() {
				loadingArticles = false;
				removeLoader();
				loadImages();
				$(window).trigger('loadImages');
			},
		});
	});
}

function addArticlesOutput( data ) {
	const $html = $(data);
	const href = $html.find('.load-more').attr('href');

	$('#articles').append( $html.find('#articles').html() );

	if ( typeof(href) !== 'undefined' ) {
		$pagingNext.attr('href', href );
		$pagingNext.show();
		loadingArticles = false;
	} else {
		$pagingNext.hide();
	}
}
