export function addLoader() {
	const $loader = $('.crb-loader');

	if ( ! $loader.length ) {
		return;
	}

	$loader.addClass('is-active');
}

export function removeLoader() {
	const $loader = $('.crb-loader');

	if ( ! $loader.length ) {
		return;
	}

	$loader.removeClass('is-active');
}
