import addClasses from './modules/addClasses';
import sliders from './modules/sliders';
import mobile__menu from './modules/mobile__menu';
import equalizeHeight from './modules/equalizeHeight';
import scrollAnimation from './modules/scrollAnimation';
import loadImages from './modules/loadImages';
import initLoadMorePosts from './modules/loadMorePosts';
import initSearchForm from './modules/searchForm';

addClasses();
sliders();
mobile__menu();
equalizeHeight();
scrollAnimation();
loadImages();
initLoadMorePosts();
initSearchForm();
