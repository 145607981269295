const $win = $( window );
const $doc = $( document );

const loadImages = () => {
	const winH = $win.height();

	$( '.js-lazy_image' ).each(function() {
		const $elementImage = $(this);

		$win.on( 'scroll load loadImages', function() {
			if ( $elementImage.offset().top <= $doc.scrollTop() + winH ) {
				if ( !$elementImage.hasClass( 'imageLoaded' ) ) {
					let srcImage = $elementImage.data( 'src' );
					$elementImage.attr( 'src', srcImage );
					$elementImage.addClass( 'imageLoaded' );
				}
			}
		});
	});
};

export default loadImages;
