export default function() {
	$('.btn--search').on('click', function(event) {
		event.preventDefault();

		$(this)
			.stop()
			.next('.search-form')
			.addClass('is-active');
	});

	searchSendRequest();
	closeSearchForm();
}

let requestSend = false;
let searching = false;

function searchSendRequest() {

	$('.bar .search__field').on('keyup', function() {
		const $serachForm = $('.search-form.is-active');

		if ( requestSend ) {
			return;
		}

		clearTimeout(searching);

		searching = setTimeout(() => {
			$.ajax({
				url: crbOptions.ajaxUrl,
				dataType: 'html',
				data: {
					'action': 'search_autocomplete',
					'searchValue': $(this).val(),
				},
				beforeSend: function() {
					requestSend = true;
					$serachForm.find('.loading').removeClass('hidden');
				},
				success: function(data) {
					$serachForm.find('.search-results').html( data );
				},
				error: function(errorThrown) {
					console.log(errorThrown);
				},
				complete: function() {
					$serachForm.find('.loading').addClass('hidden');
					requestSend = false;
				}
			});
		}, 250);
	});
}

function closeSearchForm() {
	$(document).on('mouseup touchend', function(e) {
		const $subscribe = $('.search-form.is-active');

		if ( ! $subscribe.is(e.target) && $subscribe.has(e.target).length === 0 ) {
			$subscribe.removeClass('is-active');
		}
	});
}
