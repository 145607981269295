const $win = $( window );
const $doc = $( document );

const mobile__menu = () => {
	$( '.js-btn--menu' ).on( 'click', function (e) {
		$( this ).toggleClass( 'js-active-menu' );
		$( 'body' ).toggleClass( 'js-active-menu' );
		$( 'body' ).removeClass( 'js_sub_menu' )
		e.preventDefault();
	});

	$win.on('load resize', function() {
		if ( $win.width() >= 1200 ) {
			return;
		}

		$( '.mobile__nav li:has(ul) > a' ).on( 'click', function(e) {
			let $targetMenu = $(this).parent().find( 'ul' );
			if(!$(this).parent().hasClass( 'clicked_link' )) {
				$( '.mobile__nav li:has(ul)' ).removeClass( 'clicked_link' );

				$(this).parent().addClass( 'clicked_link' );

				if ( $targetMenu.is( ':hidden' )) {
					$( '.mobile__nav li ul' ).hide( 400 );
					$targetMenu.show( 400 );
				}

				e.preventDefault();
			} else {
				return true;
			}
		});

		$( '.nav-mobile ul li.menu-item-has-children > a' ).on( 'click', function(e) {
			e.preventDefault();

			$( 'body' ).addClass( 'js_sub_menu' )
		});

		$( '.mobile__nav .close__submenu' ).on( 'click', function(e) {
			e.preventDefault();

			$( 'body' ).removeClass( 'js_sub_menu' )
		});
	});

};

export default mobile__menu;
