const $win = $( window );
const $doc = $( document );

const addClasses = () => {
	$win.on( 'load', function() {
		$( 'body' ).addClass( 'loaded' );
	});

	$win.on( 'load scroll', function() {
		let scroll = $win.scrollTop();

		if ( scroll >= 1 ) {
			$( 'body' ).addClass( 'scrolled' );
		} else {
			$( 'body' ).removeClass( 'scrolled' );
		}
	});
};

export default addClasses;
