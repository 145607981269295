const $win = $( window );
const $doc = $( document );

const scrollAnimation = () => {
	const winH = $win.height();

	$( '.js-floatUp' ).each(function() {
		const $element = $(this);

		$win.on( 'scroll load', function() {
			if ( $element.offset().top <= $doc.scrollTop() + winH ) {
				setTimeout(function() {
					$element.addClass('visible');
				}, 1);
			}
		});
	});
};

export default scrollAnimation;
