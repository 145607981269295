const $win = $( window );
const $doc = $( document );

const equalizeHeight = () => {
	$win.on( 'load resize', function() {
		if ( $win.width() > 767 ) {}
	});

	$win.on( 'load resize scroll orientationchange', function() {
		if ( $win.width() > 1023 ) {
			$( '.section-categories' ).each( function() {
				let largeBoxHeight = $(this).find( '.box.large' ).height();
				let largeBoxHeightHalf = largeBoxHeight / 2;

				$( this ).find( '.box.large ~ .box' ).css( 'min-height', largeBoxHeightHalf );
				$( this ).find( '.box.large ~ .box' ).equalizeHeight();
			});
		} else {
			$( '.section-categories' ).each( function() {
				$( this ).find( '.box.large ~ .box' ).css( 'min-height', 'auto' );
				$( this ).find( '.box.large ~ .box' ).css( 'height', 'auto' );
			});
		}
	});

	$.fn.equalizeHeight = function() {
		let maxHeight = 0, itemHeight;

		this.css( 'height', '' );

		for ( let i = 0; i < this.length; i++ ) {
			itemHeight = $( this[i] ).height();
			if ( maxHeight < itemHeight ) {
				maxHeight = itemHeight;
			}
		}

		return this.height( maxHeight );
	}
};

export default equalizeHeight;
