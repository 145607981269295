import 'slick-carousel';

const $win = $( window );
const $doc = $( document );

const sliders = () => {
	$win.on( 'load', function() {
		$( '.js-slider .slider__slides' ).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			autoplay: true,
			autoplaySpeed: 10000,
			arrows: false,
			dots: true,
			draggable: false
		});
	});
};

export default sliders;
